import { Module } from 'vuex';
import { ArtsState } from '@/types/State/ArtsState';
import { RootState } from '@/types/State/RootState';
import getters from '@/store/arts/getters';
import mutations from './mutations';
import actions from './actions';

export const state: ArtsState = {
  data: [],
};

const namespaced = true;

export const artsStateModule: Module<ArtsState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
