import { ActionTree } from 'vuex';
import { ApiResponse } from '@/types/main';
import { Cluster, BotStatusState } from '@/types/State/BotStatusState';
import { RootState } from '@/types/State/RootState';
import api from '@/utils/api/main';

const actions: ActionTree<BotStatusState, RootState> = {
  fetchGlobalData({ commit }): void {
    api.getMetrics()
      .then((response) => {
        if (response.status !== 200) {
          throw Error();
        }
        return response.json();
      })
      .then((apiResponse: ApiResponse) => {
        const dataToPush = Object
          .entries(apiResponse.data.bot_stats)
          .map(([key, value]) => ({ [key]: value } as unknown as unknown));
        commit('globalDataLoaded', dataToPush);
      })
      .catch();
  },
  fetchClustersData({ commit }): void {
    api.getMetricsClusters()
      .then((response) => {
        if (response.status !== 200) {
          throw Error();
        }
        return response.json();
      })
      .then((apiResponse: ApiResponse) => {
        const dataToPush = Object
          .entries(apiResponse.data)
          .map(([key, value]) => ({ [key]: value } as unknown as Cluster));
        commit('clustersDataLoaded', dataToPush);
      })
      .catch();
  },
};

export default actions;
