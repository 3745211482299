import { MutationTree } from 'vuex';
import { ArtistData, ArtsState } from '@/types/State/ArtsState';

const mutations: MutationTree<ArtsState> = {
  dataLoaded(state, payload: ArtistData[]) {
    state.data = payload;
  },
};

export default mutations;
