<template>
  <div class="col-md-6 col-lg-4 d-flex align-items-stretch">
    <div class="feature justify-content-center text-center shadow">
      <div class="part-1">
        <font-awesome-icon :icon="icon" class="icon" />
        <h3 class="title">{{ title }}</h3>
      </div>
      <div class="part-2">
        <p class="description text-white">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Feature extends Vue {
  @Prop() title: string;

  @Prop() description: string;

  @Prop() icon: string;
}
</script>
