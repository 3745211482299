import { ActionTree } from 'vuex';
import { ApiResponse } from '@/types/main';
import { ArtsState, ArtistData, ArtData } from '@/types/State/ArtsState';
import { RootState } from '@/types/State/RootState';
import api from '@/utils/api/main';

const actions: ActionTree<ArtsState, RootState> = {
  fetchData({ commit }): void {
    api.getArts()
      .then((response) => {
        if (response.status !== 200) {
          throw Error();
        }
        return response.json();
      })
      .then((apiResponse: ApiResponse) => {
        apiResponse.data.reverse();
        const tmp: { [key: string]: ArtistData } = {};
        apiResponse.data.forEach((artData: ArtData) => {
          if (artData.hidden === false) {
            if (!(artData.artist_details.name in tmp)) {
              tmp[artData.artist_details.name] = {
                images: [],
                artistDetails: artData.artist_details,
              };
            }
            tmp[artData.artist_details.name].images.push({
              src: artData.artworkfull_url,
              description: artData.title,
            });
          }
        });
        const toSave = Object
          .entries(tmp)
          .map(([key, value]) => ({ [key]: value } as unknown as ArtistData));
        commit('dataLoaded', toSave);
      })
      .catch();
  },
};

export default actions;
