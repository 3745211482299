<template>
  <div class="container-fluid">
    <Jumbotron />
    <FeaturesSection />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Jumbotron from '@/components/Home/Jumbotron.vue';
import FeaturesSection from '@/components/Home/Features/FeaturesSection.vue';

@Component({
  components: {
    Jumbotron, FeaturesSection,
  },
  metaInfo: {
    title: '- A multipurpose Discord bot',
  },
})
export default class Home extends Vue { }
</script>
