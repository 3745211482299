<template>
  <div class="container-fluid navbar-container">
    <nav class="navbar navbar-expand-lg navbar-dark text-white py-1">
      <div class="brandLink">
        <router-link
          class="navbar-brand text-light font-weight-bold d-inline-block"
          to="/"
        >
          Martine
        </router-link>
      </div>
      <button
        class="navbar-toggler collapsed ml-auto border-0"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="hamburger" />
        <span class="hamburger" />
        <span class="hamburger" />
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <ul class="navbar-nav ml-auto align-items-center">
          <li class="nav-item" v-for="(value, index) in data" :key="index">
            <NavLink
              :text="value.text"
              :link="value.link"
              :icon="value.icon"
              :isRouterLink="value.isRouterLink"
              :openExternally="value.openExternally"
              :activePage="activePage"
              @click.native="trackClick(value.id)"
            />
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { navBarTabs } from '@/utils/constants';
import { trackUmamiEvent } from '@/utils/umami';
import Button from '@/components/layout/Button.vue';
import NavLink from './NavLink.vue';

@Component({
  components: {
    Button,
    NavLink,
  },
})
export default class Navbar extends Vue {
  data = navBarTabs;

  get activePage(): number {
    return this.$store.state.activePage;
  }

  trackClick(event: string, data: object = {}): void {
    trackUmamiEvent(event, { ...data, component: 'navbar' });
  }
}
</script>

<style lang="scss" scoped>
.nav-link {
  font-size: 18px;
}
@media (max-width: 1200px) {
  .nav-link {
    font-size: 15px;
  }
}
a.text-light:hover,
.nav-link.active {
  color: var(--primary) !important;
}

.navbar-container {
  background-color: #252a31d8;

  .navbar-toggler .hamburger {
    display: block;
    background-color: var(--primary);
    height: 3px;
    width: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;
    opacity: 1;
    transition: all 0.35s ease-out;
    transform-origin: center left;
  }

  // Bless stackoverflow again: https://stackoverflow.com/a/64265878
  .navbar-toggler .hamburger:nth-child(1) {
    margin-top: 0.3em;
  }
  .navbar-toggler:not(.collapsed) .hamburger:nth-child(1) {
    transform: translate(15%, -33%) rotate(45deg);
  }
  .navbar-toggler:not(.collapsed) .hamburger:nth-child(2) {
    opacity: 0;
  }
  .navbar-toggler:not(.collapsed) .hamburger:nth-child(3) {
    transform: translate(15%, 33%) rotate(-45deg);
  }
  .navbar-toggler .hamburger:nth-child(1) {
    transform: translate(0%, 0%) rotate(0deg);
  }
  .navbar-toggler .hamburger:nth-child(2) {
    opacity: 1;
  }
  .navbar-toggler .hamburger:nth-child(3) {
    transform: translate(0%, 0%) rotate(0deg);
  }
}
</style>
