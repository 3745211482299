<template>
  <section class="section-features shadow">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-md-10 col-lg-8">
          <div class="header-section animate__animated animate__fadeIn">
            <div class="header-text">
              <h2 class="title">Martine's <span>Features</span></h2>
            </div>
            <p class="lead text-white">
              Martine comes with various features for you and your server, such
              as:
            </p>
          </div>
        </div>
      </div>
      <div
        class="row justify-content-center pb-5 animate__animated animate__fadeIn"
      >
        <Feature
          v-for="(value, index) in data"
          :key="index"
          :title="value.title"
          :description="value.description"
          :icon="value.icon"
        />
      </div>
      <div
        class="justify-content-center text-center pt-5 pb-4 animate__animated animate__fadeIn"
      >
        <p>
          <span class="h4 lead">Don't have Martine in your server yet?</span>
          <br />
          <span class="h4 lead">
            Invite her and get started with <code>,help</code> command
          </span>
        </p>
        <InviteBtn />
      </div>
      <div
        class="justify-content-center text-center animate__animated animate__fadeIn"
      >
        <span class="h4 lead">
          Or check out
          <router-link to="/commands">all commands</router-link> that Martine
          has!
        </span>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { botFeatures } from '@/utils/constants';
import { nsfwAttr } from '@/utils/checkiframe';
import InviteBtn from '@/components/layout/InviteBtn.vue';
import Feature from './Feature.vue';

@Component({
  components: {
    Feature, InviteBtn,
  },
})
export default class FeaturesSection extends Vue {
  data = nsfwAttr() ? botFeatures : botFeatures.filter((feature) => !feature.title.includes('NSFW'));
}
</script>

<style>
:root {
  --purple-ish: #986edf;
}

.section-features {
  padding-top: 110px;
  padding-bottom: 90px;
  font-family: "Poppins", sans-serif;
  font-display: swap;
  background-color: #32334a;
  color: #fff;
}

.section-features .header-section {
  margin-bottom: 35px;
}

.section-features .header-text {
  letter-spacing: 0.1em;
}

.section-features .header-section .title {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 25px;
  text-transform: uppercase;
  font-weight: 700;
}

.section-features .header-section .title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 140px;
  height: 1px;
  background-color: var(--purple-ish);
}

.section-features .header-section .title:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 45px;
  height: 3px;
  background-color: var(--purple-ish);
}

.section-features .header-section .title span {
  color: var(--purple-ish);
}

.section-features .feature {
  margin-top: 40px;
  background-color: #0c0d16;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}
@media (min-width: 992px) {
  .section-features .feature:hover {
    transform: scale(1.03);
  }
}

.section-features .feature .part-1 {
  padding: 25px 20px 20px;
  border-bottom: 2px solid #1d1e23;
}

.section-features .feature .part-1 .icon {
  margin-bottom: 25px;
  font-size: 75px;
  color: var(--purple-ish);
}

.section-features .feature .part-1 .title {
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.8em;
}

.section-features .feature .part-2 {
  padding: 30px 40px 30px;
}

.section-features .feature .part-2 .description {
  margin-bottom: 22px;
  color: #6f6f71;
  font-size: 14px;
  line-height: 1.8em;
}

.section-features .feature .part-2 a {
  color: var(--white);
  font-size: 14px;
  text-decoration: none;
}

.section-features .feature .part-2 a .icon {
  margin-right: 10px;
  color: #f70037;
}
</style>
