import { GetterTree } from 'vuex';
import { BotStatusState } from '@/types/State/BotStatusState';
import { RootState } from '@/types/State/RootState';

const getters: GetterTree<BotStatusState, RootState> = {
  clusterStats(state) {
    const { clustersData } = state;
    return Object.assign({}, ...clustersData);
  },
  globalStats(state) {
    const { globalStatsData } = state;
    const defaultValues = {
      guild_count: 0,
      user_count: 0,
      shard_count: 0,
      ping: 0,
      channels_count: 0,
      text_channels: 0,
      voice_channels: 0,
      emojis_count: 0,
    };
    return Object.assign(defaultValues, ...globalStatsData);
  },
};

export default getters;
