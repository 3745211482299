import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { ackeeTracker } from './utils/ackee';
import './utils/fontawesome';
import './utils/logversion';

Vue.config.productionTip = false;

ackeeTracker.record('54f457a9-f69a-45fc-b1b9-e6106ff267c9');

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
