<template>
  <div class="pop-ups" />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { },
})
export default class DisplayPopups extends Vue { }
</script>
