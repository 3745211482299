import { MutationTree } from 'vuex';
import { Cluster, BotStatusState } from '@/types/State/BotStatusState';

const mutations: MutationTree<BotStatusState> = {
  clustersDataLoaded(state, payload: Map<string, Cluster>) {
    state.clustersData = payload;
  },
  globalDataLoaded(state, payload: Map<string, number>) {
    state.globalStatsData = payload;
  },
};

export default mutations;
