<template>
  <router-link
    :to="to"
    :title="text"
    :class="'btn btn-' + variant"
    class="btn-shadow px-3 my-2 ml-0 text-left"
  >
    {{ text }}
  </router-link>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ButtonRouterLink extends Vue {
  @Prop({ default: 'primary' })
    variant!: string;

  @Prop({ required: true })
    to!: string;

  @Prop({ required: true })
    text: string;
}
</script>
