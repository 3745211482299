import { Module } from 'vuex';
import { BotStatusState } from '@/types/State/BotStatusState';
import { RootState } from '@/types/State/RootState';
import getters from '@/store/status/getters';
import mutations from './mutations';
import actions from './actions';

export const state: BotStatusState = {
  globalStatsData: new Map(),
  clustersData: new Map(),
};

const namespaced = true;

export const botStatusStateModule: Module<BotStatusState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
