import { Module } from 'vuex';
import { CommandsState } from '@/types/State/CommandsState';
import { RootState } from '@/types/State/RootState';
import getters from '@/store/commands/getters';
import mutations from './mutations';
import actions from './actions';

export const state: CommandsState = {
  commands: [],
  latestFetch: new Date(0),
};

const namespaced = true;

export const commandsStateModule: Module<CommandsState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
