<template>
  <router-link
    v-if="isRouterLink"
    class="pl-md-1 p-3 text-light nav-link"
    :class="{ active: activePage === link }"
    data-toggle="collapse"
    data-target=".navbar-collapse.show"
    :to="link"
  >
    <font-awesome-icon v-if="icon" :icon="icon" />
    <span class="font-weight-bold"> {{ text }}</span>
  </router-link>
  <a
    v-else-if="!openExternally"
    class="pl-md-1 p-3 text-light nav-link"
    :href="link"
  >
    <font-awesome-icon v-if="icon" :icon="icon" />
    <span class="font-weight-bold"> {{ text }}</span>
  </a>
  <a
    v-else
    class="pl-md-1 p-3 text-light nav-link"
    rel="noopener"
    target="_blank"
    :href="link"
  >
    <font-awesome-icon v-if="icon" :icon="icon" />
    <span class="font-weight-bold"> {{ text }}</span>
  </a>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NavLink extends Vue {
  @Prop() link: string;

  @Prop() activePage: string;

  @Prop({ default: null })
    icon: string;

  @Prop() text: string;

  @Prop({ default: true })
    isRouterLink: boolean;

  @Prop({ default: false })
    openExternally: boolean;
}
</script>
