import { GetterTree } from 'vuex';
import { CommandsState } from '@/types/State/CommandsState';
import { RootState } from '@/types/State/RootState';

const getters: GetterTree<CommandsState, RootState> = {
  categoryNames(state) {
    const { commands } = state;
    return commands.flatMap(Object.keys);
  },
  categoryCommands(state) {
    const { commands } = state;
    return Object.assign({}, ...commands);
  },
  latestFetch(state) {
    return state.latestFetch;
  },
};

export default getters;
