import { ActionTree } from 'vuex';
import { ApiResponse } from '@/types/main';
import { Command, CommandsState } from '@/types/State/CommandsState';
import { RootState } from '@/types/State/RootState';
import api from '@/utils/api/main';

const actions: ActionTree<CommandsState, RootState> = {
  fetchData({ commit }): void {
    api.getCommands()
      .then((response) => {
        if (response.status !== 200) {
          throw Error();
        }
        return response.json();
      })
      .then((apiResponse: ApiResponse) => {
        const allCommands = Object
          .entries(apiResponse.data)
          .map(([key, value]) => ({ [key]: value } as unknown as Command));
        commit('commandsLoaded', allCommands);
      })
      .catch();
  },
};

export default actions;
