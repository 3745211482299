import { library } from '@fortawesome/fontawesome-svg-core';
import { faDiscord, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowUp,
  faArrowRight,
  faBell,
  faChartLine,
  faCheckCircle,
  faComment,
  faGamepad,
  faGavel,
  faGift,
  faGrinWink,
  faHeart,
  faHome,
  faImages,
  faKeyboard,
  faLanguage,
  faPaintBrush,
  faPlus,
  faPlusSquare,
  faSearch,
  faUserPlus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

Vue.component('font-awesome-icon', FontAwesomeIcon);

// Solid
library.add(
  faArrowUp,
  faArrowRight,
  faBell,
  faChartLine,
  faCheckCircle,
  faComment,
  faGamepad,
  faGavel,
  faGift,
  faGrinWink,
  faHeart,
  faHome,
  faImages,
  faKeyboard,
  faLanguage,
  faPaintBrush,
  faPlus,
  faPlusSquare,
  faSearch,
  faUserPlus,
  faTimes,
);
// Brands
library.add(faDiscord, faTwitterSquare);
