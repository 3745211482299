import { MutationTree } from 'vuex';
import { CategoryData, CommandsState } from '@/types/State/CommandsState';

const mutations: MutationTree<CommandsState> = {
  commandsLoaded(state, payload: CategoryData[]) {
    state.commands = payload;
    state.latestFetch = new Date();
  },
};

export default mutations;
