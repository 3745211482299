<template>
  <a
    href="//invite.martinebot.com"
    class="btn btn-primary btn-lg px-3 my-2 ml-0 text-left invite-btn"
    rel="noopener"
    target="_blank"
    @click="btnClick"
  >
    <picture>
      <source
        class="martine-lurk"
        srcset="@/assets/images/a_martine_party.webp"
        type="image/webp"
      />
      <source
        class="martine-lurk"
        srcset="@/assets/images/a_martine_party.gif"
        type="image/gif"
      />
      <img
        class="martine-lurk"
        src="@/assets/images/a_martine_party.gif"
        height="30"
        width="30"
        alt="Martine party"
      />
      <font-awesome-icon :icon="['fas', 'plus']" />
    </picture>
    Add to server
  </a>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ackeeTracker } from '@/utils/ackee';
import { trackUmamiEvent } from '@/utils/umami';

@Component
export default class InviteBtn extends Vue {
  btnClick(): void  {
    ackeeTracker.action('18385a46-1869-48c3-93aa-88e04fa59136', { key: 'Click', value: 1 });
    trackUmamiEvent('invite-btn', { component: 'hero'});
  }
}
</script>
