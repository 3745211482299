<template>
  <div class="jumbotron bg-transparent mb-0 radius-0">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-xl-6 animate__animated animate__zoomIn">
          <div
            class="animate__animated animate__pulse animate__infinite animate__slower"
          >
            <picture>
              <source
                class="bot-avatar rounded-circle"
                srcset="
                  https://cdn.martinebot.com/current/website-assets/avatar.webp
                "
                type="image/webp"
              />
              <source
                class="bot-avatar rounded-circle"
                srcset="
                  https://cdn.martinebot.com/current/website-assets/avatar.png
                "
                type="image/png"
              />
              <img
                class="bot-avatar rounded-circle"
                src="https://cdn.martinebot.com/current/website-assets/avatar.png"
                height="230"
                width="230"
                alt="Martine avatar"
              />
            </picture>
          </div>
        </div>
        <div class="col animate__animated animate__zoomIn animate__faster">
          <h1 class="bot-name font-weight-bold">Martine</h1>
          <div class="mb-3 lead text-success">
            Serving
            <strong>
              {{ globalStats.guild_count.toLocaleString("en-UK") }} servers
            </strong>
            and
            <strong>
              {{ globalStats.user_count.toLocaleString("en-UK") }} users
            </strong>
          </div>
          <div class="text-center" :class="!isMobile ? 'btn-group' : ''">
            <InviteBtn />
            <a
              href="//discord.gg/yXJwfjkp27"
              class="btn btn-dark btn-lg px-3 my-2 ml-0 text-left"
              rel="noopener"
              target="_blank"
            >
              <font-awesome-icon :icon="['fab', 'discord']" />
              Join Discord server
            </a>
          </div>
        </div>
      </div>
      <div class="row justify-content-center text-center pt-4">
        <ScrollArrow />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { BotStatusState, GlobalBotStats } from '@/types/State/BotStatusState';
import Button from '@/components/layout/Button.vue';
import ButtonRouterLink from '@/components/layout/ButtonRouterLink.vue';
import InviteBtn from '@/components/layout/InviteBtn.vue';
import ScrollArrow from '@/components/layout/ScrollArrow.vue';

const namespace = 'botStatusStateModule';

@Component({
  components: {
    Button, ButtonRouterLink, InviteBtn, ScrollArrow
  },
})
export default class Jumbotron extends Vue {
  @State('botStatusStateModule') botStatusStateModule: BotStatusState;

  @Action('fetchGlobalData', { namespace }) fetchGlobalData: () => void;

  @Getter('globalStats', { namespace }) globalStats: GlobalBotStats;

  isMobile = false;

  beforeDestroy(): void {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize(): void {
    this.isMobile = window.innerWidth < 767;
  }

  mounted(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
    this.fetchGlobalData();
  }
}
</script>

<style lang="scss">
.bot-avatar {
  width: 230px;
  height: 230px;
  border-radius: 100%;
  box-shadow: 0 0 50px 0 #a254a0;
}
.bot-name {
  background: linear-gradient(62deg, #23235f 0%, #a254a0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 6.5rem;
  user-select: none;
}

.invite-btn:hover {
  box-shadow: 0 0 25px 0 var(--white);
  background-color: var(--primary);
  border-color: unset;
}
.invite-btn:hover .martine-lurk {
  opacity: 1;
}
.martine-lurk {
  position: absolute;
  opacity: 0;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0.01s;
  margin-left: -10px;
  margin-top: -2px;
}

@media (max-width: 767.98px) {
  .bot-name {
    font-size: 3.5rem;
  }
}
</style>
