export default class ApiRequest {
  method: string;

  fullUrl: string;

  constructor(
    method: string,
    fullUrl: string,
  ) {
    this.method = method;
    this.fullUrl = fullUrl;
  }

  async send(): Promise<Response> {
    const req = fetch(this.fullUrl, {
      method: this.method,
    });
    return req;
  }
}
