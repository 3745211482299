<template>
  <footer>
    <div class="container">
      <div class="container mt-5 mb-2 text-center text-md-left">
        <div class="row mt-3">
          <div class="col-md-3 col-lg-4 col-xl-3 mb-4">
            <p>
              <router-link
                to="/"
                class="h3 brand-name-btn font-weight-bold text-white"
                @click.native="trackClick('home-btn', { position: 'left' })"
              >
                <picture>
                  <source
                    srcset="@/assets/images/martine_wave.webp"
                    type="image/webp"
                  />
                  <source
                    srcset="@/assets/images/martine_wave.png"
                    type="image/png"
                  />
                  <img
                    class="rounded-circle"
                    src="@/assets/images/martine_wave.png"
                    width="35"
                    height="35"
                    alt="Martine Waving"
                  />
                </picture>
                Martine
              </router-link>
            </p>
            <a
              class="lead"
              href="//invite.martinebot.com"
              rel="noopener"
              target="_blank"
              @click="trackClick('invite-btn')"
            >
              <font-awesome-icon :icon="['fas', 'plus-square']" /> Invite me
            </a>
            <br />
            <a
              class="lead"
              href="//discord.gg/yXJwfjkp27"
              rel="noopener"
              target="_blank"
              @click="trackClick('discord-server-btn')"
            >
              <font-awesome-icon :icon="['fab', 'discord']" /> Join Discord server
            </a>
            <br />
            <a
              class="lead"
              href="//twitter.com/Martine_Bot"
              rel="noopener"
              target="_blank"
              @click="trackClick('twitter-btn')"
            >
              <font-awesome-icon :icon="['fab', 'twitter-square']" /> Twitter
            </a>
          </div>
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-2">
            <span class="h5 text-uppercase">Navigation</span>
            <ul class="list-unstyled">
              <li>
                <router-link to="/" @click.native="trackClick('home-btn', { position: 'nav' })">Home</router-link>
              </li>
              <li>
                <router-link to="/commands" @click.native="trackClick('commands-btn')">Commands</router-link>
              </li>
              <li>
                <router-link to="/art" @click.native="trackClick('art-btn')">Artists showcase</router-link>
              </li>
              <li>
                <router-link to="/status" @click.native="trackClick('status-btn')">Bot status</router-link>
              </li>
              <li>
                <router-link to="/premium" @click.native="trackClick('premium-btn')">Premium</router-link>
              </li>
              <li>
                <router-link to="/about" @click.native="trackClick('about-btn')">About the bot</router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-2">
            <span class="h5 text-uppercase">External</span>
            <ul class="list-unstyled">
              <li>
                <a
                  href="//patreon.martinebot.com"
                  rel="noopener"
                  target="_blank"
                  @click="trackClick('patreon-btn')"
                >
                  Patreon</a
                >&#8203;
                <a
                  href="//donate.martinebot.com"
                  rel="noopener"
                  target="_blank"
                  @click="trackClick('kofi-btn')"
                >Ko-fi
                </a>
              </li>
              <li>
                <a
                  href="//metrics.martinebot.com"
                  rel="noopener"
                  target="_blank"
                  @click="trackClick('metrics-btn')"
                >
                  Metrics
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-4 col-lg-3 col-xl-3">
            <span class="h5 text-uppercase">Terms</span>
            <ul class="list-unstyled">
              <li>
                <router-link to="/privacy-policy">Privacy Policy</router-link>
              </li>
              <li>
                <router-link to="/terms-of-service">Terms of Service</router-link>
              </li>
              <li>
                <router-link to="/bot-rules">Bot Rules</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="container text-center small mb-4">
          <p>Made with 💜 and 🍞. Be kind to one another.</p>
          <p>
            © 2018-present
            <a href="//predamart.me" rel="noopener" target="_blank" @click="trackClick('predamart.me-btn')">Predä</a>
            &amp;
            <a href="//lepain.dev" rel="noopener" target="_blank" @click="trackClick('lepain.dev-btn')">Bread</a>
            &#8203;
            <a
              href="//www.dmca.com/Protection/Status.aspx?ID=e50d17e4-705b-4450-bf79-68a28d31e4ea"
              title="DMCA.com Protection Status"
              class="dmca-badge"
            >
              <img
                src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-11.png?ID=e50d17e4-705b-4450-bf79-68a28d31e4ea"
                height="20"
                width="100"
                alt="DMCA.com Protection Status"
              />
            </a>
          </p>
          <button
            class="btn btn-outline-primary btn-sm"
            onClick="window.scrollTo({top: 0, behavior: 'smooth'});"
          >
            <font-awesome-icon :icon="['fas', 'arrow-up']" />
            Back to Top
            <font-awesome-icon :icon="['fas', 'arrow-up']" />
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { trackUmamiEvent } from '@/utils/umami';

@Component
export default class Footer extends Vue {
  trackClick(event: string, data: object = {}): void {
    trackUmamiEvent(event, { ...data, component: 'footer' });
  }
}
</script>

<style lang="scss" scoped>
footer {
  background-color: #181c20d8;
  padding: 0.01rem;

  .brand-name-btn,
  .brand-name-btn:hover,
  .brand-name-btn:visited,
  .brand-name-btn:focus {
    text-decoration: none;
  }
  .brand-name-btn:hover {
    img {
      content: url(../../assets/images/a_martine_wave.webp);
    }
  }
}
</style>
