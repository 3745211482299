import { GetterTree } from 'vuex';
import { ArtsState } from '@/types/State/ArtsState';
import { RootState } from '@/types/State/RootState';

const getters: GetterTree<ArtsState, RootState> = {
  allArts(state) {
    return state.data;
  },
};

export default getters;
