<template>
  <div id="app">
    <main class="page-container">
      <div class="page-content">
        <DisplayPopups />
        <Navbar />
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </main>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script lang="ts">
import vfmPlugin from 'vue-final-modal';
import { Component, Vue } from 'vue-property-decorator';
import DisplayPopups from '@/components/layout/Popups/DisplayPopups.vue';
import Footer from '@/components/layout/Footer.vue';
import Navbar from '@/components/layout/Navbar/Navbar.vue';
import VueOnlineProp from '@/utils/vue-online-prop';

Vue.use(VueOnlineProp);
Vue.use(vfmPlugin);

@Component({
  components: {
    Navbar, Footer, DisplayPopups,
  },
  metaInfo: {
    titleTemplate: 'Martine %s',
  },
})
export default class App extends Vue { }
</script>

<style lang="scss">
@import "./assets/styles/bootstrap4-neon-glow.css";

:root {
  --secondary: #36acf1;
  --clear-purple: #a57fe6;
  --clear-pink: #d7a8e5;
  --clear-gray: #818181;
  --primary: var(--clear-purple);
}
.clear-purple {
  color: var(--clear-purple);
}
.text-white {
  color: #dbdbdb !important;
}

html {
  overflow-y: scroll;
}
body {
  color: #dbdbdb !important;
}
.page-container {
  position: relative;
  min-height: 70vh;

  /* Footer stay at the bottom and never move. thx */
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #181818;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #3a3a3a;
}

::selection {
  background: var(--clear-pink);
  color: #181818;
}
::-moz-selection {
  background: var(--clear-pink);
  color: #181818;
}

hr {
  background-color: var(--clear-gray);
}
a {
  color: var(--primary);
}
code {
  color: var(--secondary);
  background-color: #3a3a3a;
  padding: 6px;
  border-radius: 4px;
}
a:hover {
  color: #c3b6eb;
}
.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}
.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
