import ApiRequest from './constructor';

class Api {
  baseUrl = process.env.NODE_ENV === 'development' ? '/api' : 'https://api.martinebot.com/martine/v1';

  createRequest(method: string, path: string): ApiRequest {
    return new ApiRequest(method, this.baseUrl + path);
  }

  getMetrics(): Promise<Response> {
    return this.createRequest('GET', '/metrics').send();
  }

  getMetricsClusters(): Promise<Response> {
    return this.createRequest('GET', '/metrics/clusters').send();
  }

  getCommands(): Promise<Response> {
    return this.createRequest('GET', '/commands').send();
  }

  getArts(): Promise<Response> {
    return this.createRequest('GET', '/art?webp=true').send();
  }

  getValidateReportsCategory(category: string): Promise<Response> {
    return this.createRequest('GET', `/imagereports/validcategories?category=${category}`).send();
  }
}

export default new Api();
