<template>
  <div class="scroll-arrow" ref="arrow"></div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ScrollArrow extends Vue {
  mounted(): void {
    window.addEventListener('scroll', this.handleScroll);
  }

  beforeDestroy(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(): void {
    const { scrollTop } = document.documentElement;
    const arrow = this.$refs.arrow as HTMLElement;
    if (!arrow) return;

    if (scrollTop > 200) {
      arrow.classList.add('fade');
    } else {
      arrow.classList.remove('fade');
    }
  }
}
</script>

<style scoped>
.scroll-arrow {
  display: block;
  text-align: center;
  font-size: 20px;
  z-index: 100;
  text-decoration: none;
  width: 24px;
  height: 24px;
  border-bottom: 4px solid var(--clear-purple);
  border-right: 4px solid var(--clear-purple);
  z-index: 9;
  -webkit-transform: translate(-50%, 0%) rotate(45deg);
  -moz-transform: translate(-50%, 0%) rotate(45deg);
  transform: translate(-50%, 0%) rotate(45deg);
  -webkit-animation: fade_move_down 4s ease-in-out infinite;
  -moz-animation: fade_move_down 4s ease-in-out infinite;
  animation: fade_move_down 4s ease-in-out infinite;
}

@-webkit-keyframes fade_move_down {
  0% {
    -webkit-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}

@-moz-keyframes fade_move_down {
  0% {
    -moz-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -moz-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}

@keyframes fade_move_down {
  0% {
    transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}

.fade {
  opacity: 0 !important;
  border-bottom: unset !important;
  border-right: unset !important;
  transition: 1s all ease-out;
}
</style>
