<template>
  <a
    v-if="openExternally === false"
    :href="href"
    :class="'btn btn-' + variant"
    class="btn-shadow px-3 my-2 ml-0 text-left"
  >
    {{ text }}
  </a>
  <a
    v-else
    :href="href"
    :class="'btn btn-' + variant"
    class="btn-shadow px-3 my-2 ml-0 text-left"
    rel="noopener"
    target="_blank"
  >
    {{ text }}
  </a>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
  @Prop({ default: 'primary' })
    variant!: string;

  @Prop({ required: false })
    href!: string;

  @Prop({ required: true })
    text: string;

  @Prop({ default: false })
    openExternally: boolean;
}
</script>
